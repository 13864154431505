import Api from "@/api/Api";
import Button from "@/components/button/Button";
import FilterableMultiSelect from "@/components/form/FilterableMultiSelect";
import Input from "@/components/form/Input";
import CloseButton from "@/components/modal/CloseButton";
import Modal from "@/components/modal/Modal";
import { H2 } from "@/components/typography";
import useSortedCategories from "@/helpers/hooks/use_sorted_categories";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import { route } from "@/utils/route_utils";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
const SuccessMessage = ({ group, onClose }) => /* @__PURE__ */ React.createElement("div", { className: "text-center" }, /* @__PURE__ */ React.createElement(H2, { className: "mt-0 text-center" }, "Thank you!"), /* @__PURE__ */ React.createElement(
  "img",
  {
    className: "block mx-auto w-1/3",
    src: group.logo_url,
    alt: group.name
  }
), /* @__PURE__ */ React.createElement("p", { className: "mt-4 mb-8" }, "You are now subscribed to ", group.name, "."), /* @__PURE__ */ React.createElement("div", { className: "mx-auto md:w-24" }, /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: onClose }, "Close")));
const StayConnectedForm = ({ group, onClose, onSuccess, user }) => {
  const [categories, setCategories] = useState([]);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const availableCategories = group.group_categories.filter(
    (c) => {
      var _a;
      return !c.featured && !((_a = c == null ? void 0 : c.parent) == null ? void 0 : _a.featured);
    }
  );
  const sortedCategories = useSortedCategories(availableCategories);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (e.currentTarget.reportValidity() === false) {
      return;
    }
    const body = {
      subscription: {
        email,
        first_name: firstName,
        group_category_ids: categories.map((c) => c.value),
        last_name: lastName,
        name: "connected",
        subscribable_id: group.id,
        user_id: user == null ? void 0 : user.id
      }
    };
    try {
      await Api.utility.post(route("subscriptions"), body);
      onSuccess();
      setSubmitting(false);
      mixpanel.track("groupsubscribe_complete", {
        kind: "group",
        group_name: group.slug,
        type: user ? "user" : "visitor"
      });
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(H2, { className: "mt-0 text-center" }, "Stay connected with ", group.name, "!"), /* @__PURE__ */ React.createElement(
    "img",
    {
      className: "block mx-auto w-1/3",
      src: group.logo_url,
      alt: group.name
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "max-w-sm mx-auto" }, /* @__PURE__ */ React.createElement("p", null, "Subscribe to be notified when a ", group.member_name || "member", " you know passes away. (", /* @__PURE__ */ React.createElement("span", { className: "font-bold text-red-700" }, "*"), " ", "indicates a required field)")), /* @__PURE__ */ React.createElement("form", { className: "lg:px-8", method: "post", onSubmit: handleSubmit }, !(user == null ? void 0 : user.id) && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("label", { className: "block mx-auto my-6" }, "Your Email ", /* @__PURE__ */ React.createElement("span", { className: "font-bold text-red-700" }, "*"), /* @__PURE__ */ React.createElement(
    Input,
    {
      id: "email",
      type: "email",
      isHighlighted: true,
      onChange: setEmail,
      required: true,
      value: email
    }
  )), /* @__PURE__ */ React.createElement("div", { className: "grid md:grid-cols-2 gap-2 md:gap-8 my-4" }, /* @__PURE__ */ React.createElement("label", null, "Your First Name ", /* @__PURE__ */ React.createElement("span", { className: "text-red-700" }, "*"), /* @__PURE__ */ React.createElement(
    Input,
    {
      isHighlighted: true,
      onInput: (e) => setFirstName(e.currentTarget.value),
      required: true,
      value: firstName
    }
  )), /* @__PURE__ */ React.createElement("label", null, "Your Last Name ", /* @__PURE__ */ React.createElement("span", { className: "text-red-700" }, "*"), /* @__PURE__ */ React.createElement(
    Input,
    {
      isHighlighted: true,
      onInput: (e) => setLastName(e.currentTarget.value),
      required: true,
      value: lastName
    }
  )))), Object.entries(sortedCategories).map(([name, values]) => /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "gap-4 grid md:grid-cols-5 items-center my-6",
      key: name
    },
    /* @__PURE__ */ React.createElement("div", { className: "col-span-2" }, "Your ", name, " ", /* @__PURE__ */ React.createElement("span", { className: "font-bold text-red-700" }, availableCategories.find((category) => category.name === name).required_for_subscription ? "*" : "")),
    /* @__PURE__ */ React.createElement("div", { className: "col-span-3" }, /* @__PURE__ */ React.createElement(
      FilterableMultiSelect,
      {
        limit: group.group_categories.find(
          (category) => category.name === name
        ).subcategory_limit,
        options: values.map(({ id, name: categoryName }) => ({
          label: categoryName,
          value: id
        })),
        required: availableCategories.find((category) => category.name === name).required_for_subscription,
        selected: categories,
        setStateFunction: setCategories
      }
    ))
  )), /* @__PURE__ */ React.createElement("div", { className: "flex gap-4 md:max-w-xs mx-auto my-8 w-full" }, /* @__PURE__ */ React.createElement(Button, { color: "outline", onClick: onClose, type: "button" }, "Cancel"), /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      fullWidth: true,
      isDisabled: submitting,
      type: "submit"
    },
    "Subscribe"
  ))), /* @__PURE__ */ React.createElement("p", { className: "text-center text-sm opacity-70" }, "Your contact information will also be shared with ", group.name, " to ensure you receive these and other important communications."));
};
const StayConnected = ({ group, onSuccess, user }) => {
  const history = useHistory();
  const { search } = useLocation();
  const jotformId = new URLSearchParams(search).get("jotform_id");
  const [subscribed, setSubscribed] = useState(false);
  useEffect(() => {
    var _a;
    if (!group)
      return;
    (_a = window.jotformEmbedHandler) == null ? void 0 : _a.call(
      window,
      `iframe[id='JotFormIFrame-${jotformId || group.experimental_stay_connected_jotform_id}'`,
      "https://form.jotform.com/"
    );
    mixpanel.track("groupsubscribe_start", {
      kind: "group",
      group_name: group.slug,
      form_id: jotformId || group.experimental_stay_connected_jotform_id || null,
      type: user ? "user" : "visitor"
    });
  }, [group]);
  const handleClose = () => {
    if (subscribed && onSuccess) {
      onSuccess();
    }
    onClose();
  };
  const onClose = () => history.push(route("groupShow", { slug: group.slug }));
  if (!group || !user)
    return;
  return /* @__PURE__ */ React.createElement(Modal, { isVisible: true, onClose: handleClose }, /* @__PURE__ */ React.createElement("div", { className: "flex justify-end" }, /* @__PURE__ */ React.createElement(CloseButton, { onClose })), !subscribed && !(group == null ? void 0 : group.experimental_stay_connected_jotform_id) && /* @__PURE__ */ React.createElement(
    StayConnectedForm,
    {
      group,
      onClose,
      onSuccess: () => setSubscribed(true),
      user
    }
  ), !subscribed && (group == null ? void 0 : group.experimental_stay_connected_jotform_id) && /* @__PURE__ */ React.createElement(
    "iframe",
    {
      id: `JotFormIFrame-${jotformId || group.experimental_stay_connected_jotform_id}`,
      className: "mix-h-[50vh] w-full overflow-hidden",
      src: `https://form.jotform.com/${jotformId || group.experimental_stay_connected_jotform_id}`
    }
  ), subscribed && /* @__PURE__ */ React.createElement(SuccessMessage, { group, onClose: handleClose }));
};
export default StayConnected;
