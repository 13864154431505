import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
const defaultCrumbs = () => {
  const pathnames = window.location.pathname.split("/").filter((x) => x);
  return pathnames.map((_, index) => {
    const url = `/${pathnames.slice(0, index + 1).join("/")}`;
    return {
      label: pathnames[index],
      href: url
    };
  });
};
const Breadcrumb = ({ crumbs }) => {
  const crumbsList = crumbs || defaultCrumbs();
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "flex flex-col md:flex-row items-center gap-4 justify-between mb-8" }, /* @__PURE__ */ React.createElement("nav", { "aria-label": "breadcrumbs", className: "font-semibold text-slate-950" }, crumbsList.map(({ label, href }, i) => /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("inline-block", {
        'before:content-[">"] before:mx-2': i > 0
      }),
      key: i
    },
    href ? /* @__PURE__ */ React.createElement(Link, { className: "text-lifeweb-blue underline", to: href }, label) : /* @__PURE__ */ React.createElement("span", null, label)
  )))));
};
export default Breadcrumb;
