import Api from "@/api/Api";
import Button from "@/components/button/Button";
import ThemeRail from "@/components/theme/ThemeRail";
import { useIdentityQuery, useProjectQuery } from "@/queries";
import { flashMessageFromResponseError } from "@/utils/alert_utils";
import Modeler from "@/utils/modeler/modeler";
import { route } from "@/utils/route_utils";
import FILE_UPLOAD_SETTINGS from "@/variables/file_uploads";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import ImageEditor from "@uppy/image-editor";
import XHRUpload from "@uppy/xhr-upload";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import MemoriesAddLifeThreadModal from "../memories/components/MemoriesAddLifeThreadModal";
import { useBreadcrumb } from "../providers/BreadcrumbContext";
import SettingsTitle from "./SettingsTitle";
const BulkUpload = () => {
  const { slug } = useParams();
  const { data: project } = useProjectQuery(slug);
  const { data: user } = useIdentityQuery();
  const breadcrumb = useBreadcrumb();
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [defaultThemes, setDefaultThemes] = useState([]);
  const [showThemes, setShowThemes] = useState(false);
  const [themes, setThemes] = useState([]);
  const [isAddLifeThreadModalVisible, setIsAddLifeThreadModalVisible] = useState(false);
  const [newLifeThreadName, setNewLifeThreadName] = useState("");
  const targetRef = useRef(null);
  const uploadedRef = useRef([]);
  const onAddTheme = (theme) => {
    setThemes([...themes, theme]);
  };
  const onRemoveTheme = (theme) => {
    const entry = themes.find((formTheme) => formTheme.id === theme.id);
    const filteredThemes = themes.filter((formTheme) => formTheme !== entry);
    setThemes(filteredThemes);
  };
  let uppy;
  useEffect(() => {
    uppy = new Uppy({
      debug: true,
      restrictions: {
        maxFileSize: FILE_UPLOAD_SETTINGS.maxFileSize,
        allowedFileTypes: FILE_UPLOAD_SETTINGS.allowedFileTypes,
        maxNumberOfFiles: FILE_UPLOAD_SETTINGS.bulkMaxNumberOfFiles
      }
    }).use(Dashboard, {
      inline: true,
      target: targetRef.current,
      hideProgressAfterFinish: true,
      hideCancelButton: true,
      proudlyDisplayPoweredByUppy: false
    }).use(ImageEditor, {
      target: Dashboard
    }).use(XHRUpload, {
      endpoint: "/upload",
      limit: 10
      // limit concurrent uploads (NOT TOTAL UPLOADS)
    });
    uppy.on("upload", onStartUpload);
    uppy.on("complete", onImageComplete);
  }, []);
  useEffect(() => {
    var _a;
    if (!project)
      return;
    setDefaultThemes(
      project.themes.filter((theme) => theme.status === "active")
    );
    breadcrumb.setCrumbs([
      {
        label: `${(_a = project.prefix) != null ? _a : ""} ${project.full_name}`,
        href: route("project", { slug })
      },
      {
        label: "Admin Settings"
      },
      {
        label: "Bulk Add Photos"
      }
    ]);
    mixpanel.track("settings_viewed", {
      kind: "project",
      project_id: project.id,
      type: "batch-upload"
    });
  }, [project]);
  const onStartUpload = () => {
    setIsUploading(true);
  };
  const onImageComplete = (result) => {
    setIsUploading(false);
    if (result.successful.length > 0) {
      setShowThemes(true);
      result.successful.forEach((rst) => {
        uploadedRef.current.push(rst.response.body.data);
      });
    }
  };
  const createTheme = async (name) => {
    const url = `api/v1/themes`;
    try {
      const body = {
        theme: {
          name,
          project_id: project.id
        }
      };
      const response = await Api.utility.post(url, body);
      const newTheme = new Modeler(response.data).build();
      onAddTheme(newTheme);
      refreshProjectThemes();
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const refreshProjectThemes = async () => {
    const url = route("themesIndex", {
      project_id: project.id,
      status: "active"
    });
    try {
      const response = await Api.utility.get(url);
      const data = new Modeler(response.data).build();
      setDefaultThemes(data);
      const inactiveTheme = themes.find(
        (theme) => !data.some((formTheme) => theme.id === formTheme.id)
      );
      if (inactiveTheme) {
        onRemoveTheme(inactiveTheme);
      }
    } catch (err) {
      flashMessageFromResponseError(err);
    }
  };
  const onChangeLifeThread = (lifeThreadName) => {
    setNewLifeThreadName(lifeThreadName);
  };
  const onAddLifeThread = async () => {
    createTheme(newLifeThreadName);
    setIsAddLifeThreadModalVisible(false);
  };
  const onAddLifeThreadModalOnClose = () => {
    setIsAddLifeThreadModalVisible(false);
  };
  const onCustomLifeThreadClicked = () => {
    setNewLifeThreadName("");
    setIsAddLifeThreadModalVisible(true);
  };
  const onSubmit = async () => {
    const url = route("memoryBulkUpload");
    const formatBody = uploadedRef.current.map((i) => ({
      project_id: project.id,
      user_id: user.id,
      theme_ids: themes.map((formTheme) => formTheme.id),
      memory_images_attributes: [
        {
          image: JSON.stringify(i)
        }
      ]
    }));
    const body = {
      memories: formatBody,
      project_id: project.id
    };
    try {
      setIsUploading(true);
      const response = await Api.utility.post(url, body);
      const data = response.data;
      window.location = data.redirect_path;
    } catch (err) {
      flashMessageFromResponseError(err);
      setIsUploading(false);
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(SettingsTitle, null, "Bulk Add Photos"), /* @__PURE__ */ React.createElement("p", { className: "my-4" }, "Please upload up to 50 images. Each one will be added to the LifeWeb as a new memory."), /* @__PURE__ */ React.createElement("p", { className: "mb-8" }, "You can update the text, images and Life Threads directly from the LifeWeb page after the memories are added."), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "bulk-upload__image-wrapper mx-auto max-w-2xl",
      ref: targetRef
    }
  ), /* @__PURE__ */ React.createElement("div", { style: { display: showThemes ? "inherit" : "none" } }, /* @__PURE__ */ React.createElement("h5", { className: "bulk-upload__title" }, t(
    "views.settings.projectSettings.bulkUpload.titles.lifeThread"
  ), /* @__PURE__ */ React.createElement("span", { className: "form-label is-red" }, t("form.label.required"))), /* @__PURE__ */ React.createElement("p", { className: "bulk-upload__text" }, "Please choose at least one Life Thread to organize this group of memories for now. You can always edit Life Threads later."), /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile bulk-upload__select-wrapper" }, /* @__PURE__ */ React.createElement("div", { className: "column is-12-tablet is-12-mobile" }, /* @__PURE__ */ React.createElement(
    ThemeRail,
    {
      isRemovable: true,
      onAddTheme,
      onRemoveTheme,
      onAddNewLifeThread: onCustomLifeThreadClicked,
      selectedThemes: themes,
      themes: defaultThemes
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "columns is-mobile bulk-upload__action-wrapper is-aligned-right" }, /* @__PURE__ */ React.createElement("div", { className: "column is-6-tablet is-12-mobile" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      color: "secondary",
      isDisabled: isUploading || themes.length === 0,
      loading: isUploading,
      onClick: onSubmit
    },
    t(
      "views.settings.projectSettings.bulkUpload.actions.im_done"
    )
  ))))), /* @__PURE__ */ React.createElement(
    MemoriesAddLifeThreadModal,
    {
      isVisible: isAddLifeThreadModalVisible,
      lifeThreadName: newLifeThreadName,
      onAddLifeThread,
      onChangeLifeThread,
      onClose: onAddLifeThreadModalOnClose
    }
  ));
};
export default BulkUpload;
