import { useDetectClickOutside } from "@/helpers/hooks/useDetectClickOutside";
import classNames from "classnames";
import React, { useEffect, useId, useMemo, useRef, useState } from "react";
const numbers = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten"
];
const FilterableMultiSelect = ({
  limit,
  options,
  placeholder: placeholderProp,
  ref,
  required,
  selected,
  setStateFunction
}) => {
  const defaultPlaceholder = placeholderProp || limit ? `Select ${limit > 1 ? "up to " : ""}${numbers[limit]}...` : "Select one or more...";
  const [open, setOpen] = useState(false);
  const [placeholder, setPlaceholder] = useState(defaultPlaceholder);
  const [search, setSearch] = useState("");
  const multiselectId = useId();
  const handleBlur = () => {
    setDynamicPlaceholder();
    setOpen(false);
    setSearch("");
  };
  const componentRef = useRef(null);
  useDetectClickOutside(componentRef, handleBlur);
  const selectedOptions = useMemo(
    () => options.filter(
      (option) => selected.map(({ value }) => value).includes(option.value)
    ),
    [options, selected]
  );
  const searchResults = useMemo(
    () => options.filter(
      ({ label }) => label.toLowerCase().includes(search.toLowerCase())
    ),
    [options, search]
  );
  const handleSelect = (option) => (e) => {
    e.stopPropagation();
    if (selected.find((s) => s.value === option.value)) {
      setStateFunction(selected.filter((s) => s.value !== option.value));
    } else if (selectedOptions.length !== limit) {
      setStateFunction([
        options.find((o) => o.value === option.value),
        ...selected
      ]);
    }
  };
  const setDynamicPlaceholder = () => {
    setPlaceholder(
      selectedOptions.length > 0 ? `${selectedOptions.map((category) => category.label).join(", ")}` : defaultPlaceholder
    );
  };
  const handleFocus = () => {
    setPlaceholder(
      `Type to search ${limit ? `(select ${limit > 1 ? "up to " : ""}${numbers[limit]})` : ""}`
    );
    setOpen(true);
  };
  useEffect(() => setDynamicPlaceholder(), [selectedOptions]);
  const isSelected = (option) => Boolean(selectedOptions.find((s) => s.value === option.value));
  return /* @__PURE__ */ React.createElement("div", { className: "relative select-none", ref: componentRef }, /* @__PURE__ */ React.createElement("div", { className: "cursor-pointer flex gap-2 items-center relative" }, /* @__PURE__ */ React.createElement(
    "input",
    {
      className: classNames("border h-full px-2 py-3 rounded w-full", {
        "placeholder-black": !open && selectedOptions.length > 0
      }),
      type: "text",
      onFocus: handleFocus,
      onInput: (e) => setSearch(e.currentTarget.value),
      placeholder,
      value: search,
      "aria-controls": multiselectId,
      role: "combobox",
      "aria-expanded": open
    }
  ), /* @__PURE__ */ React.createElement(
    "img",
    {
      className: classNames("absolute block px-2 py-4 right-0 transform", {
        "rotate-180": open
      }),
      onClick: () => open ? handleBlur() : handleFocus(),
      src: require("@/assets/images/icons/caret-black-down.svg"),
      alt: open ? "Close dropdown" : "Open dropdown"
    }
  )), open && /* @__PURE__ */ React.createElement(
    "div",
    {
      id: multiselectId,
      className: "absolute bg-white border cursor-pointer divide-y flex flex-col my-2 overflow-hidden rounded shadow w-full z-10",
      role: "listbox",
      "aria-multiselectable": "true"
    },
    searchResults.length > 0 ? searchResults.map((option) => /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames("hover:bg-gray-50 p-3 relative", {
          "opacity-40 cursor-not-allowed": selectedOptions.length === limit && !isSelected(option)
        }),
        key: option.value,
        onClick: (e) => handleSelect(option)(e),
        role: "option",
        "aria-selected": isSelected(option)
      },
      isSelected(option) && /* @__PURE__ */ React.createElement("span", { className: "absolute border-l-4 border-lifeweb-blue h-full left-0 top-0" }),
      option.label
    )) : /* @__PURE__ */ React.createElement("div", { className: "p-3 relative" }, "No results")
  ), /* @__PURE__ */ React.createElement(
    "select",
    {
      className: "opacity-0 absolute h-px w-px overflow-hidden",
      multiple: true,
      ref,
      required,
      defaultValue: selectedOptions.map((o) => o.value),
      tabIndex: -1,
      "aria-hidden": "true"
    },
    options.map((option) => /* @__PURE__ */ React.createElement(
      "option",
      {
        key: option.value,
        value: option.value,
        selected: isSelected(option)
      },
      option.label
    ))
  ));
};
export default FilterableMultiSelect;
